import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/core/store'
import { setPayed } from '@/core/store/catalog.store'
import { setSelectedCategories } from '@/core/store/catalog.store'

const categoriesMap = [
  {
    name: 'allPins',
    // filters now replaced by filter index (see Home.page)
    //    filter: ['10', '7', '5', '3'],
    index: 0,
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="44" height="24" viewBox="0 0 44 24" fill="none">
            <path d="M22 2C19.8783 2 17.8434 2.84285 16.3431 4.34315C14.8429 5.84344 14 7.87827 14 10C14 15.4 21.05 21.5 21.35 21.76C21.5311 21.9149 21.7616 22.0001 22 22.0001C22.2384 22.0001 22.4689 21.9149 22.65 21.76C23 21.5 30 15.4 30 10C30 7.87827 29.1571 5.84344 27.6569 4.34315C26.1566 2.84285 24.1217 2 22 2ZM22 19.65C19.87 17.65 16 13.34 16 10C16 8.4087 16.6321 6.88258 17.7574 5.75736C18.8826 4.63214 20.4087 4 22 4C23.5913 4 25.1174 4.63214 26.2426 5.75736C27.3679 6.88258 28 8.4087 28 10C28 13.34 24.13 17.66 22 19.65ZM22 6C21.2089 6 20.4355 6.2346 19.7777 6.67412C19.1199 7.11365 18.6072 7.73836 18.3045 8.46927C18.0017 9.20017 17.9225 10.0044 18.0769 10.7804C18.2312 11.5563 18.6122 12.269 19.1716 12.8284C19.731 13.3878 20.4437 13.7688 21.2196 13.9231C21.9956 14.0775 22.7998 13.9983 23.5307 13.6955C24.2616 13.3928 24.8864 12.8801 25.3259 12.2223C25.7654 11.5645 26 10.7911 26 10C26 8.93913 25.5786 7.92172 24.8284 7.17157C24.0783 6.42143 23.0609 6 22 6ZM22 12C21.6044 12 21.2178 11.8827 20.8889 11.6629C20.56 11.4432 20.3036 11.1308 20.1522 10.7654C20.0009 10.3999 19.9613 9.99778 20.0384 9.60982C20.1156 9.22186 20.3061 8.86549 20.5858 8.58579C20.8655 8.30608 21.2219 8.1156 21.6098 8.03843C21.9978 7.96126 22.3999 8.00087 22.7654 8.15224C23.1308 8.30362 23.4432 8.55996 23.6629 8.88886C23.8827 9.21776 24 9.60444 24 10C24 10.5304 23.7893 11.0391 23.4142 11.4142C23.0391 11.7893 22.5304 12 22 12Z" fill="url(#paint0_linear_1_687)"/>
            <path d="M34.5 3.58325C36.1797 3.58325 37.7906 4.25051 38.9784 5.43823C40.1661 6.62596 40.8333 8.23686 40.8333 9.91656C40.8333 14.1915 35.2521 19.0207 35.0146 19.2265C34.8712 19.3492 34.6887 19.4166 34.5 19.4166C34.3113 19.4166 34.1288 19.3492 33.9854 19.2265C33.7083 19.0207 30.7602 14.0771 31.5 11.4999C32.1478 7.83256 30.5417 4.77084 30.5417 4.77084C30.5417 4.77084 32.8203 3.58325 34.5 3.58325ZM34.5 17.5561C36.1863 15.9728 39.25 12.5607 39.25 9.91656C39.25 8.65678 38.7496 7.44861 37.8588 6.55781C36.968 5.66702 35.7598 5.16658 34.5 5.16658C33.2402 5.16658 32.5208 5.56251 32.5208 5.56251C32.5208 5.56251 32.9167 8.65688 32.9167 9.91665C32.9167 12.5608 32.8138 15.9807 34.5 17.5561ZM34.5 6.7499C35.1263 6.7499 35.7386 6.93562 36.2593 7.28358C36.7801 7.63154 37.186 8.1261 37.4256 8.70473C37.6653 9.28336 37.728 9.92007 37.6058 10.5343C37.4836 11.1486 37.182 11.7129 36.7392 12.1557C36.2963 12.5986 35.7321 12.9002 35.1178 13.0224C34.5035 13.1445 33.8668 13.0818 33.2882 12.8422C32.7095 12.6025 32.6088 12.129 32.2608 11.6082C31.9129 11.0875 32.9167 10.543 32.9167 9.91665C32.9167 9.0768 31.667 8.27126 32.2608 7.67739C32.8547 7.08353 33.6602 6.7499 34.5 6.7499ZM34.5 11.4999C34.8132 11.4999 35.1193 11.407 35.3797 11.233C35.64 11.0591 35.843 10.8118 35.9628 10.5225C36.0827 10.2332 36.114 9.9148 36.0529 9.60766C35.9918 9.30053 35.841 9.01841 35.6196 8.79698C35.3982 8.57554 35.116 8.42475 34.8089 8.36365C34.5018 8.30256 34.1834 8.33391 33.8941 8.45375C33.6048 8.57359 33.3575 8.77653 33.1835 9.03691C33.0095 9.29728 32.9167 9.6035 32.9167 9.91665C32.9167 10.3366 33.0835 10.7392 33.3804 11.0361C33.6774 11.3331 34.0801 11.4999 34.5 11.4999Z" fill="url(#paint1_linear_1_687)"/>
            <path d="M9.49999 3.58325C7.82028 3.58325 6.20938 4.25051 5.02165 5.43823C3.83392 6.62596 3.16666 8.23686 3.16666 9.91656C3.16666 14.1915 8.74791 19.0207 8.98541 19.2265C9.1288 19.3492 9.31129 19.4166 9.49999 19.4166C9.68869 19.4166 9.87118 19.3492 10.0146 19.2265C10.2917 19.0207 13.2398 14.0771 12.5 11.4999C11.8522 7.83256 13.4583 4.77084 13.4583 4.77084C13.4583 4.77084 11.1797 3.58325 9.49999 3.58325ZM9.49999 17.5561C7.81374 15.9728 4.74999 12.5607 4.74999 9.91656C4.74999 8.65678 5.25043 7.44861 6.14123 6.55781C7.03203 5.66702 8.24021 5.16658 9.49999 5.16658C10.7598 5.16658 11.4792 5.56251 11.4792 5.56251C11.4792 5.56251 11.0833 8.65688 11.0833 9.91665C11.0833 12.5608 11.1862 15.9807 9.49999 17.5561ZM9.49999 6.7499C8.87368 6.7499 8.26144 6.93562 7.74068 7.28358C7.21993 7.63154 6.81405 8.1261 6.57437 8.70473C6.33469 9.28336 6.27198 9.92007 6.39417 10.5343C6.51636 11.1486 6.81795 11.7129 7.26082 12.1557C7.70368 12.5986 8.26793 12.9002 8.8822 13.0224C9.49648 13.1445 10.1332 13.0818 10.7118 12.8422C11.2905 12.6025 11.3912 12.129 11.7392 11.6082C12.0871 11.0875 11.0833 10.543 11.0833 9.91665C11.0833 9.0768 12.333 8.27126 11.7392 7.67739C11.1453 7.08353 10.3398 6.7499 9.49999 6.7499ZM9.49999 11.4999C9.18684 11.4999 8.88071 11.407 8.62034 11.233C8.35996 11.0591 8.15702 10.8118 8.03718 10.5225C7.91734 10.2332 7.88599 9.9148 7.94708 9.60766C8.00817 9.30053 8.15897 9.01841 8.3804 8.79698C8.60184 8.57554 8.88396 8.42475 9.1911 8.36365C9.49823 8.30256 9.81659 8.33391 10.1059 8.45375C10.3952 8.57359 10.6425 8.77653 10.8165 9.03691C10.9905 9.29728 11.0833 9.6035 11.0833 9.91665C11.0833 10.3366 10.9165 10.7392 10.6196 11.0361C10.3226 11.3331 9.91992 11.4999 9.49999 11.4999Z" fill="url(#paint2_linear_1_687)"/>
            <defs>
            <linearGradient id="paint0_linear_1_687" x1="22" y1="2" x2="22" y2="22.0001" gradientUnits="userSpaceOnUse">
            <stop stop-color="yellow"/>
            <stop offset="1" stop-color="#C2C2C2"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1_687" x1="35.6875" y1="3.58325" x2="35.6875" y2="19.4166" gradientUnits="userSpaceOnUse">
            <stop stop-color="yellow"/>
            <stop offset="1" stop-color="#C2C2C2"/>
            </linearGradient>
            <linearGradient id="paint2_linear_1_687" x1="8.31249" y1="3.58325" x2="8.31249" y2="19.4166" gradientUnits="userSpaceOnUse">
            <stop stop-color="yellow"/>
            <stop offset="1" stop-color="#C2C2C2"/>
            </linearGradient>
            </defs>
          </svg>`,
  },
  {
    name: 'top',
    // filters now replaced by index
    // filter: ['10', '7'],
    index: 1,
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="27" height="24" viewBox="0 0 27 24" fill="none">
            <path d="M22.9245 8.03383L17.1897 7.16031L14.6265 1.73051C14.1673 0.762659 12.8366 0.750356 12.3735 1.73051L9.81029 7.16031L4.07553 8.03383C3.04712 8.18967 2.63497 9.51431 3.38077 10.273L7.52973 14.4971L6.54842 20.4641C6.37179 21.5427 7.45908 22.3506 8.36973 21.8462L13.5 19.0288L18.6303 21.8462C19.5409 22.3465 20.6282 21.5427 20.4516 20.4641L19.4703 14.4971L23.6192 10.273C24.365 9.51431 23.9529 8.18967 22.9245 8.03383ZM17.4488 13.8081L18.3791 19.484L13.5 16.806L8.62094 19.484L9.55122 13.8081L5.60244 9.78908L11.0585 8.96067L13.5 3.79334L15.9415 8.96067L21.3976 9.78908L17.4488 13.8081Z" fill="url(#paint0_linear_1_1494)"/>
            <defs>
            <linearGradient id="paint0_linear_1_1494" x1="13.5" y1="1" x2="13.5" y2="22" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFEEC3"/>
            <stop offset="1" stop-color="#FFC700"/>
            </linearGradient>
            </defs>
          </svg>`,
  },
  {
    name: 'mustSee',
    // filters now replaced by filter index (see Home.page)
    // filter: ['!'],
    index: 2,
    svg: `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1758 11.53C11.7223 10.763 11.6613 9.9553 11.2824 8.50235C10.533 5.62882 10.8314 4.05383 13.4147 2.18916L14.6756 1.27905L14.9806 2.80389C15.3047 4.42441 15.8369 5.42671 17.2036 7.35464C17.2579 7.43122 17.2579 7.43122 17.3124 7.50815C19.281 10.2854 20 11.9596 20 15C20 18.6884 16.2714 22 12 22C7.72841 22 4 18.6888 4 15C4 14.9311 4.00008 14.9331 3.98839 14.6285C3.89804 12.2718 4.33381 10.4274 6.09707 8.43586C6.46962 8.01509 6.89309 7.61068 7.36963 7.22371L8.42162 6.36946L8.92767 7.62658C9.30158 8.55547 9.7397 9.28567 10.2346 9.82151C10.6538 10.2754 10.9647 10.8461 11.1758 11.53ZM7.59449 9.76166C6.23712 11.2947 5.91441 12.6606 5.98692 14.5518C6.00042 14.9039 6 14.8915 6 15C6 17.5279 8.78361 20 12 20C15.2161 20 18 17.5275 18 15C18 12.4582 17.4317 11.135 15.6807 8.6647C15.6265 8.58818 15.6265 8.58818 15.5719 8.51125C14.5085 7.01111 13.8747 5.96759 13.4553 4.80052C12.7705 5.62118 12.8107 6.43739 13.2176 7.99766C13.967 10.8712 13.6686 12.4462 11.0853 14.3108L9.61228 15.3741L9.50185 13.5608C9.4313 12.4023 9.16907 11.6156 8.7654 11.1785C8.36657 10.7467 8.00647 10.2411 7.68356 9.66279C7.65343 9.69566 7.62375 9.72862 7.59449 9.76166Z" fill="url(#paint0_linear_1_1498)"/>
      <defs>
      <linearGradient id="paint0_linear_1_1498" x1="11.9886" y1="1.27905" x2="11.9886" y2="22" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FFEBCE"/>
      <stop offset="1" stop-color="#FF9900"/>
      </linearGradient>
      </defs>
    </svg>
        `,
  },
]

export const CategoriesForMap = ({
  isExcursionBeingSelected,
  setExcursionBeingSelected,
}: {
  isExcursionBeingSelected: boolean
  setExcursionBeingSelected: (state: boolean) => void
}) => {
  const { selectedCategories } = useAppSelector((store) => store.catalogStore)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation('navigation')

  const handleCategoryChange = (categoryIndex: number) => {
    dispatch(
      setSelectedCategories({ category: categoryIndex, included: selectedCategories.included }),
    )
    if (window.location.pathname !== '/') {
      setExcursionBeingSelected(false)
    }
    window.scrollTo(0, 0)
  }

  const getCurrentFilter = () => {
    return selectedCategories
  }

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    // Set payet if p=475089hfkdjfdjgfliureyg341f7hg4g13
    // console.log(searchParams.get('p'))
    // console.log(searchParams.get('p') == '475089hfkdjfdjgfliureyg341f7hg4g13')
    if (searchParams.get('p') && searchParams.get('p') == '475089hfkdjfdjgfliureyg341f7hg4g13')
      dispatch(setPayed(true))

    //If startExcursion in URL OR tour parameter exists then open excursion pop-up
    if (location.search.substring(1) === 'startExcursion' || searchParams.get('tour')) {
      // navigate('/map?startExcursion')
      navigate('/map?tour=' + searchParams.get('tour'))
      setExcursionBeingSelected(true)
    }
  }, [])

  return (
    <div className="categories">
      <div className="container">
        <div className="categories__carousel">
          <ul className="categories__carousel-list">
            {categoriesMap.map((item, index) => (
              <Link
                to="/map"
                key={index}
                onClick={() => handleCategoryChange(item.index)}
                //data-value={item.filter}
              >
                <li
                  className={`categories__carousel-item ${
                    getCurrentFilter().category == index &&
                    !isExcursionBeingSelected &&
                    window.location.pathname !== '/'
                      ? 'active'
                      : ''
                  }`}
                >
                  <div
                    className="categories__carousel-locate"
                    dangerouslySetInnerHTML={{ __html: item.svg }}
                  ></div>
                  <div className="categories__carousel-content">
                    <p>{t(item.name)}</p>
                  </div>
                </li>
              </Link>
            ))}
            {
              <li className="position-relative">
                <Link
                  onClick={() => {
                    if (window.location.pathname !== '/') {
                      setExcursionBeingSelected(true)
                      // modalSettings.open = false
                    } else {
                      window.scrollTo(0, 0)
                    }
                  }}
                  to={'/map?startExcursion'}
                >
                  <div
                    className={`categories__carousel-item ${
                      isExcursionBeingSelected ? 'active' : ''
                    }`}
                  >
                    <div
                      className="categories__carousel-locate"
                      dangerouslySetInnerHTML={{
                        __html: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 22.0001C7.30622 22.0001 8.41746 21.1653 8.82929 20.0001H17.5C19.9853 20.0001 22 17.9854 22 15.5001C22 13.0148 19.9853 11.0001 17.5 11.0001H6.5C5.11929 11.0001 4 9.88079 4 8.50008C4 7.11936 5.11929 6.00008 6.5 6.00008H9.58579L8.29289 7.29297L9.70711 8.70718L13.4142 5.00008L9.70711 1.29297L8.29289 2.70718L9.58579 4.00008H6.5C4.01472 4.00008 2 6.01479 2 8.50008C2 10.9854 4.01472 13.0001 6.5 13.0001H17.5C18.8807 13.0001 20 14.1194 20 15.5001C20 16.8808 18.8807 18.0001 17.5 18.0001H8.82929C8.41746 16.8349 7.30622 16.0001 6 16.0001C4.34315 16.0001 3 17.3432 3 19.0001C3 20.6569 4.34315 22.0001 6 22.0001ZM5 19.0001C5 18.4478 5.44772 18.0001 6 18.0001C6.55228 18.0001 7 18.4478 7 19.0001C7 19.5524 6.55228 20.0001 6 20.0001C5.44772 20.0001 5 19.5524 5 19.0001ZM18 2.00008C19.6569 2.00008 21 3.34322 21 5.00008C21 6.65693 19.6569 8.00008 18 8.00008C16.3431 8.00008 15 6.65693 15 5.00008C15 3.34322 16.3431 2.00008 18 2.00008ZM17 5.00008C17 4.44779 17.4477 4.00008 18 4.00008C18.5523 4.00008 19 4.44779 19 5.00008C19 5.55236 18.5523 6.00008 18 6.00008C17.4477 6.00008 17 5.55236 17 5.00008Z" fill="url(#paint0_linear_1_1504)"/>
                                <defs>
                                <linearGradient id="paint0_linear_1_1504" x1="12" y1="1.29297" x2="12" y2="22.0001" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#DCFFAF"/>
                                <stop offset="1" stop-color="#96DD3D"/>
                                </linearGradient>
                                </defs>
                                </svg>`,
                      }}
                    ></div>
                    <div className="categories__carousel-content">
                      <p data-test={window.location.href}>TOURS</p>
                    </div>
                  </div>
                </Link>
              </li>
            }
          </ul>
        </div>
      </div>
    </div>
  )
}
