export const landing_data_tours = [
  {
    img: '/images/tours/ancient1/ancient1.webp',
    name: 'Ancient Rome, Part 1',
    description:
      'Explore the legendary origins of Rome, where myth and history intertwine to create a foundation story filled with betrayal, power, and destiny',
    link: '/map',
    id: 'ancient1',
    categoryId: 2,
    price: 10,
    time: '3-4',
    length: '3.5',
    rating: '4.9 (7)',
  },
  {
    img: '/images/tours/ancient2/ancient2.webp',
    name: 'Ancient Rome, Part 2',
    description:
      "Witness Rome's epic transformation from republic to empire: explore the power, politics, and architecture that shaped a great civilization!",
    link: '/map',
    id: 'ancient2',
    categoryId: 2,
    price: 9,
    time: '2.5-3',
    length: '2.5',
    rating: '4.9 (2)',
  },
  {
    img: '/images/sr2y7kj4j0.webp',
    name: 'Angels and Demons',
    description:
      "Follow Robert Langdon's path in a race against time, exploring Rome's historic landmarks and the secrets of the Illuminati",
    link: '/map',
    id: 'a_n_d',
    categoryId: 2,
    price: 11,
    time: '4',
    length: '4.5',
    rating: '5.0 (11)',
  },
  {
    img: '/images/sr2ykhnznp.webp',
    name: 'In the Footsteps of Princess Ann',
    description:
      "Experience Rome like Audrey Hepburn: tour the unforgettable sites that made 'Roman Holiday' a timeless classic",
    link: '/map',
    id: 'roman_holidays',
    categoryId: 2,
    price: 11,
    time: '3',
    length: '3',
    rating: '5.0 (3)',
  },
  {
    img: '/images/sr2ykhf3fg.webp',
    name: 'Baroque Rome',
    description:
      "Discover Baroque Rome's splendor, shaped by the genius and fierce competition between Bernini and Borromini",
    link: '/map',
    id: 'baroque',
    categoryId: 2,
    price: 10,
    time: '3',
    length: '2',
    rating: '4.8 (5)',
  },
  {
    img: '/images/sr2ykk9bxw.webp',
    name: 'Baroque Rome v2',
    description:
      "Explore Baroque Rome on an extended tour: uncover how artists like Bernini, Borromini, and Caravaggio transformed the city's art and architecture",
    link: '/map',
    id: 'baroque_v2',
    categoryId: 2,
    price: 12,
    time: '4.5',
    length: '4',
    rating: '4.8 (4)',
  },
  {
    img: '/images/tours/overview_3h/overview_3h.webp',
    name: 'Overview Tour of Rome in 3 Hours',
    description:
      "Explore 2,700 years of history in 3 hours: walk through Rome's evolution from ancient empire to modern capital",
    link: '/map',
    id: 'overview_3h',
    categoryId: 1,
    price: 10,
    time: '3',
    length: '3',
    rating: '5.0 (8)',
  },
  {
    img: '/images/sr2yk3bs17.webp',
    name: 'Overview Tour of Rome in 4.5 Hours',
    description:
      "Experience Rome's rich history in just 4.5 hours (you can do it in 4 if you walk faster): walk through the ages from the Colosseum to Piazza Navona",
    link: '/map',
    id: 'overview_4h',
    categoryId: 1,
    price: 12,
    time: '4',
    length: '4',
    rating: '4.8 (3)',
  },
  {
    img: '/images/sr2y7vv20j.webp',
    name: 'Renaissance Rome',
    description:
      "Journey through Renaissance Rome: uncover how popes, artists, and courtesans revived classical antiquity and reshaped the city's cultural landscape",
    link: '/map',
    id: 'renaissance_quick',
    categoryId: 2,
    price: 9,
    time: '3',
    length: '2',
    rating: '4.9 (2)',
  },
  {
    img: '/images/sr2yk691rs.webp',
    name: 'Renaissance Rome v2',
    description:
      "A longer journey through Renaissance Rome's sites: uncover the fascinating interplay between art, power, and the women who shaped the era",
    link: '/map',
    id: 'renaissance_quick2',
    categoryId: 2,
    price: 11,
    time: '3',
    length: '2',
    rating: '4.9 (5)',
  },
  {
    img: '/images/sr2yk3bs17.webp',
    name: 'Rome in One Day, Part 1 ',
    description:
      "Experience Rome's ancient grandeur in one walk: from the Colosseum to the Spanish Steps, uncover the city's rise and legacy",
    link: '/map',
    id: 'rome_1d_1',
    categoryId: 1,
    price: 9,
    time: '3',
    length: '3',
    rating: '4.9',
  },
  {
    img: '/images/sr2ykh8pr7.webp',
    name: 'Rome in One Day, Part 2',
    description:
      "Continue your big journey through Renaissance Rome: explore artistic rivalry, Baroque elegance, and the Vatican's power in this timeless city",
    link: '/map',
    id: 'rome_1d_2',
    categoryId: 1,
    price: 8,
    time: '3',
    length: '3.4',
    rating: '4.8 (2)',
  },
  {
    img: '/images/tours/jewish_rome/p1_JewsInRome.jpg',
    name: 'Jewish Rome',
    description:
      "Explore the deep connections between Jewish history and Rome, uncovering the rich cultural impact that shaped the city's most iconic landmarks",
    link: '/map',
    id: 'jewish_rome',
    categoryId: 0,
    price: 16,
    time: '4',
    length: '5',
    rating: '5.0 (3)',
  },
]

export const landing_data = [
  {
    img: './images/sr2yk3bs17.webp',
    name: 'The Colosseum',
    description:
      'Immerse yourself in the grandeur of ancient Rome at this iconic amphitheatre, where you can witness the captivating history of gladiatorial battles and experience the architectural marvel of the Roman Empire.',
    lat: 41.8902102,
    lon: 12.4922309,
    link: '/map',
    id: 1,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2yk47988.webp',
    name: 'Roman Forum',
    description:
      'Step back in time and explore the heart of ancient Rome, where you can wander through the remains of temples, government buildings, and bustling marketplaces, and witness the birthplace of Western civilization.',
    lat: 41.8924623,
    lon: 12.485325,
    link: '/map',
    id: 1,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2y7gmmhc.webp',
    name: 'The Pantheon',
    description:
      "Delve into the architectural brilliance of an ancient Roman temple, boasting the world's largest unreinforced concrete dome and a breathtaking oculus that bathes the interior in celestial light.",
    lat: 41.8986108,
    lon: 12.4768729,
    link: '/map',
    id: 1,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2y7ku7jc.webp',
    name: 'Vatican Museums',
    description:
      'Embark on a mesmerizing journey through centuries of art and culture, exploring the vast collection of masterpieces housed within the walls of the Vatican City.',
    lat: 41.9064878,
    lon: 12.4536413,
    link: '/map',
    id: 1,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2y7khdhb.webp',
    name: "St. Peter's Basilica",
    description:
      "Marvel at the awe-inspiring architecture and immerse yourself in the spiritual heart of Vatican City at this monumental church, home to Michelangelo's magnificent masterpiece, the Pietà.",
    lat: 41.9021667,
    lon: 12.4539367,
    link: '/map',
    id: 1,
    category: 10,
    type: 'C',
  },
  {
    img: './images/sr2y7kj4j0.webp',
    name: "St. Peter's Square",
    description:
      " Experience the grandeur of Vatican City at this iconic plaza, where you can witness the Pope's public addresses and admire the stunning Egyptian obelisk that stands as a symbol of ancient Rome.",
    lat: 41.9022309,
    lon: 12.4545984,
    link: '/map',
    id: 1,
    category: 10,
    type: 'P',
  },
  {
    img: './images/sr2y7kjn4f.webp',
    name: 'Sistine Chapel',
    description:
      "Step into a celestial masterpiece adorned with Michelangelo's breathtaking frescoes, a true testament to the artistic genius of the Renaissance.",
    lat: 41.9029468,
    lon: 12.4544835,
    link: '/map',
    id: 1,
    category: 10,
    type: 'C',
  },
  {
    img: './images/sr2yk5f43v.webp',
    name: 'Trevi Fountain',
    description: `Make a wish and toss a coin into this iconic fountain, known for its stunning Baroque design and its role in the classic film "La Dolce Vita."`,
    lat: 41.9009325,
    lon: 12.483313,
    link: '/map',
    id: 1,
    category: 10,
    type: 'S',
  },
  {
    img: './images/sr2ykhc8nk.webp',
    name: 'Spanish Steps',
    description: `Climb the iconic staircase that connects the bustling Piazza di Spagna with the Trinità dei Monti Church, offering a picturesque vantage point of Rome's lively streets and a popular meeting spot for locals and tourists alike.`,
    lat: 41.90599,
    lon: 12.482775,
    link: '/map',
    id: 1,
    category: '10',
    type: 'P',
  },
  {
    img: './images/sr2y7gd8s2.webp',
    name: 'Piazza Navona',
    description: `Immerse yourself in the vibrant atmosphere of this historic public square, adorned with stunning fountains and bustling with lively street performers, artists, and cafes.`,
    lat: 41.8991633,
    lon: 12.4730742,
    link: '/map',
    id: 1,
    category: 10,
    type: 'P',
  },
  {
    img: './images/sr2y7sjxjz.webp',
    name: "Castel Sant'Angelo",
    description: `Discover the rich history and architectural grandeur of this ancient fortress, which now houses a fascinating museum showcasing art, weaponry, and the secrets of Rome's past.`,
    lat: 41.9030632,
    lon: 12.466276,
    link: '/map',
    id: 2,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2yk43ecd.webp',
    name: 'Capitoline Museums',
    description: ` Explore the extensive collection of ancient Roman art and artifacts, including the iconic statue of Romulus and Remus with the she-wolf, at this municipal museum in Rome.`,
    lat: 41.8929428,
    lon: 12.4825577,
    link: '/map',
    id: 2,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2ykq0rzj.webp',
    name: 'Galleria Borghese',
    description: `Delight in a captivating collection of Renaissance and Baroque art, including
            masterpieces by Caravaggio and Bernini, housed within a stunning villa surrounded by
            lush gardens.`,
    lat: 41.9142103,
    lon: 12.4921442,
    link: '/map',
    id: 2,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2y7vsung.webp',
    name: 'Piazza del Popolo',
    description: `See this iconic square, boasting an impressive Egyptian obelisk
            and surrounded by stunning twin churches, perfect for capturing memorable photos and
            soaking in the vibrant Roman atmosphere.`,
    lat: 41.910814,
    lon: 12.476302,
    link: '/map',
    id: 2,
    category: 10,
    type: 'P',
  },
  {
    img: './images/sr2ykn52q4.webp',
    name: 'Villa Borghese Gardens',
    description: `Immerse yourself in the natural beauty of this expansive landscape garden, offering a
            serene escape from the bustling city while showcasing breathtaking views of
            Rome's skyline.`,
    lat: 41.9128873,
    lon: 12.4852085,
    link: '/map',
    id: 2,
    category: 10,
    type: 'O',
  },
  {
    img: './images/sr2yk1tp7y.webp',
    name: 'Palatine Hill',
    description: `Discover the historical heart of Rome as you explore the centremost of the seven
            hills, where ancient ruins and breathtaking views transport you back to the glory days
            of the Roman Empire.`,
    lat: 41.8894036,
    lon: 12.4874662,
    link: '/map',
    id: 2,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2yhrbdy5.webp',
    name: 'Baths of Caracalla',
    description: `Step back in time and marvel at the grandeur of these ancient Roman public baths, once
            frequented by citizens seeking relaxation, socializing, and rejuvenation.`,
    lat: 41.8790382,
    lon: 12.4924394,
    link: '/map',
    id: 2,
    category: 10,
    type: 'B-M',
  },
  {
    img: './images/sr2y7v5c7y.webp',
    name: 'Ara Pacis',
    description: `Explore the ancient Roman monument that houses the beautifully preserved Altar of
            Peace, a remarkable testament to the power and artistry of the Roman Empire.`,
    lat: 41.9075974,
    lon: 12.4747708,
    link: '/map',
    id: 2,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2yk1525u.webp',
    name: 'Circus Maximus',
    description: `Experience the grandeur of an ancient Roman chariot racing stadium, where the echoes
            of thundering hooves and the cheers of spectators transport you to the thrilling world
            of ancient sporting events.`,
    lat: 41.8854149,
    lon: 12.4850727,
    link: '/map',
    id: 2,
    category: 10,
    type: 'B-M',
  },
  {
    img: './images/sr2vvw3gbj.webp',
    name: 'Appian Way',
    description: `Walk in the footsteps of ancient Romans along this historic street, lined with ancient tombs and offering a glimpse into the rich history and culture of Rome.`,
    lat: 41.8270096,
    lon: 12.5487558,
    link: '/map',
    id: 2,
    category: 10,
    type: 'O',
  },
  {
    img: './images/sr2yknc20r.webp',
    name: 'National Museum',
    description: `Immerse yourself in a captivating collection of modern and contemporary art,
            showcasing groundbreaking works that
            reflect the ever-evolving nature of human creativity.`,
    lat: 41.917042,
    lon: 41.917042,
    link: '/map',
    id: 2,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2yk7mbcn.webp',
    name: 'Basilica di Santa Maria Maggiore',
    description: `Discover the grandeur of the largest Catholic Marian church, adorned with exquisite
            mosaics and a rich history that spans centuries.`,
    lat: 41.8978678,
    lon: 12.499406,
    link: '/map',
    id: 3,
    category: 10,
    type: 'C',
  },
  {
    img: './images/sr2yk3sqz5.webp',
    name: 'Basilica of San Clemente',
    description: `Uncover the layers of history as you explore this minor Roman Catholic basilica, where
            a unique underground archaeological site reveals ancient ruins and a stunning
            12th-century mosaic.`,
    lat: 41.8893347,
    lon: 12.4975757,
    link: '/map',
    id: 3,
    category: 10,
    type: 'C',
  },
  {
    img: './images/sr2yk9475c.webp',
    name: 'Archbasilica of St. John Lateran',
    description: `Experience the awe-inspiring beauty of Rome's cathedral, adorned with breathtaking sculptures and boasting the title of the oldest church in the Western world.`,
    lat: 41.8858811,
    lon: 12.505673,
    link: '/map',
    id: 3,
    category: 10,
    type: 'C',
  },
  {
    img: './images/sr2vxb665q.webp',
    name: 'Papal Palace of Castel Gandolfo',
    description: `Step into the former summer residence of the Pope, a 17th-century palace nestled in
            the charming town of Castel Gandolfo, offering stunning panoramic views of the
            surrounding countryside.`,
    lat: 41.7497932,
    lon: 12.6485192,
    link: '/map',
    id: 3,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2yhss8u4.webp',
    name: 'Catacomb of Callixtus',
    description: `Journey beneath the surface of Rome to explore the intricate network of burial
            chambers and discover the fascinating history of early Christian tombs.`,
    lat: 41.8608245,
    lon: 12.5087505,
    link: '/map',
    id: 3,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2y5vw7bc.webp',
    name: 'Centrale Montemartini',
    description: `Immerse yourself in the unique blend of ancient history and industrial innovation as
            you explore this captivating museum, where classical sculptures are displayed amidst
            the backdrop of a former power plant.`,
    lat: 41.866817,
    lon: 12.4780576,
    link: '/map',
    id: 3,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2y7g9t1q.webp',
    name: 'Chiostro del Bramante',
    description: `Delve into the captivating world of Italian Renaissance art and architecture as you
            explore this stunningly preserved cloister, adorned with exquisite frescoes and
            showcasing masterpieces by renowned artists.`,
    lat: 41.9000087,
    lon: 12.4715909,
    link: '/map',
    id: 3,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2y7vv20j.webp',
    name: 'Santa Maria del Popolo',
    description: `Marvel at the breathtaking Renaissance artwork housed within this historic church,
            including masterpieces by Caravaggio and Bernini.`,
    lat: 41.911491,
    lon: 12.4766557,
    link: '/map',
    id: 3,
    category: 10,
    type: 'C',
  },
  {
    img: './images/sr2yk3gn2t.webp',
    name: 'Domus Aurea',
    description: `Step back in time and uncover the opulent legacy of Emperor Nero as you explore the remains of his magnificent palace, known as the "Golden House."`,
    lat: 41.8906222,
    lon: 12.4956124,
    link: '/map',
    id: 3,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2yk4fwkx1.webp',
    name: 'Domus Romane',
    description: `Discover the fascinating remnants of two ancient Roman houses, complete with remarkably preserved baths, offering a glimpse into the daily lives of the city's former inhabitants.`,
    lat: 41.8960502,
    lon: 12.484071,
    link: '/map',
    id: 3,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2ykh9qrk.webp',
    name: 'Keats-Shelley Memorial House',
    description: `Immerse yourself in the literary world of John Keats and Percy Bysshe Shelley at this unique Italian museum, showcasing their personal belongings and manuscripts.`,
    lat: 41.9057494,
    lon: 12.4825019,
    link: '/map',
    id: 4,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2ye8y17y.webp',
    name: 'MAXXI – National Museum of the 21st Century Arts',
    description: `Explore the cutting-edge exhibits and avant-garde installations at this contemporary art museum, showcasing the vibrant and ever-evolving artistic expressions of our time.`,
    lat: 41.9282451,
    lon: 12.4668557,
    link: '/map',
    id: 4,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2yk4u5xc.webp',
    name: "Trajan's Market",
    description: `Uncover the ancient shopping complex that once bustled with life, offering a glimpse
            into the vibrant commercial hub of ancient Rome.`,
    lat: 41.8956349,
    lon: 12.4862941,
    link: '/map',
    id: 4,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2y7f8yys.webp',
    name: 'Palazzo Farnese',
    description: `Marvel at the exquisite Renaissance architecture and breathtaking frescoes, showcasing
            the artistic mastery of the era within this grand palazzo.`,
    lat: 41.8947597,
    lon: 12.4707851,
    link: '/map',
    id: 4,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2yk7uvr7.webp',
    name: 'Palazzo Massimo alle Terme',
    description: `Delve into the ancient world as you explore this historic building, home to an
            impressive collection of Roman art and artifacts, including stunning frescoes and
            intricate mosaics.`,
    lat: 41.9013587,
    lon: 12.4982554,
    link: '/map',
    id: 4,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2y7gywwb.webp',
    name: 'Palazzo Montecitorio',
    description: `Discover the seat of the Italian Chamber of Deputies, where you can admire the neoclassical architecture and witness the democratic processes that shape the nation's future.`,
    lat: 41.9015668,
    lon: 12.4787435,
    link: '/map',
    id: 4,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2yk508jx.webp',
    name: 'Palazzo Venezia',
    description: `Immerse yourself in the rich history of Rome as you explore this palazzo, which served as Mussolini's headquarters during World War II and now houses a fascinating museum dedicated to the city's past.`,
    lat: 41.8964485,
    lon: 12.4813787,
    link: '/map',
    id: 4,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2yjf8h6b.webp',
    name: 'Parco degli Acquedotti',
    description: `Wander through this ancient park and be awe-struck by the towering aqueducts that have stood the test of time, offering a unique glimpse into Rome's remarkable engineering and architectural history.`,
    lat: 41.8503929,
    lon: 12.5574572,
    link: '/map',
    id: 4,
    category: 10,
    type: 'O',
  },
  {
    img: './images/sr2y7evzdt.webp',
    name: "Ponte Sant'Angelo",
    description: `Marvel at the breathtaking angel sculptures that line this iconic bridge, offering a stunning view of the Tiber River and the majestic Castel Sant'Angelo.`,
    lat: 41.901788,
    lon: 12.4664617,
    link: '/map',
    id: 4,
    category: 10,
    type: 'Ponte',
  },
  {
    img: './images/sr2y7ber7s.webp',
    name: 'Porta Portese',
    description: `Step back in time as you explore this historic city gate, which now hosts one of Rome's largest and liveliest flea markets, offering a vibrant atmosphere and a treasure trove of unique finds.`,
    lat: 41.8838834,
    lon: 12.4740583,
    link: '/map',
    id: 4,
    category: 10,
    type: 'P',
  },
  {
    img: './images/sr2y5zr71k.webp',
    name: 'Protestant Cemetery',
    description: `Discover the final resting place of renowned poets and artists, including John Keats
            and Percy Bysshe Shelley, at this cemetery located near Porta San Paolo.`,
    lat: 41.8763105,
    lon: 12.4794911,
    link: '/map',
    id: 4,
    category: 10,
    type: 'O',
  },
  {
    img: './images/sr2yhp2k61.webp',
    name: 'Pyramid of Cestius',
    description: `Uncover the mysteries of this ancient pyramid, a unique architectural marvel nestled
            near Porta San Paolo and the Protestant Cemetery.`,
    lat: 41.876489,
    lon: 12.4808979,
    link: '/map',
    id: 5,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2yk5ufrj.webp',
    name: 'Quirinal Palace',
    description: `Explore the grandeur of this historic building, which serves as the official residence of the President of the Italian Republic, offering a glimpse into Italy's political heritage.`,
    lat: 41.9009045,
    lon: 12.487295,
    link: '/map',
    id: 5,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2y7fnxyb.webp',
    name: 'Roman Ghetto',
    description: `Immerse yourself in the rich Jewish history of Rome as you wander through this vibrant
            neighborhood, filled with authentic kosher restaurants, synagogues, and a unique
            atmosphere that transports you back in time.`,
    lat: 41.8922429,
    lon: 12.4786825,
    link: '/map',
    id: 5,
    category: 10,
    type: 'B-P',
  },
  {
    img: './images/sr2ykk5zyf.webp',
    name: 'Santa Maria degli Angeli e dei Martiri',
    description: `Step inside this church building, originally a Roman bathhouse, and
            vitness a stunning blend of ancient architecture with Renaissance
            design.`,
    lat: 41.9031888,
    lon: 12.4969341,
    link: '/map',
    id: 5,
    category: 10,
    type: 'C',
  },
  {
    img: './images/sr2ye2y0ch.webp',
    name: 'Stadio Olimpico',
    description: `Experience the electric atmosphere of this iconic stadium, home to both AS Roma and SS
            Lazio, and witness the passion and excitement of Italian football firsthand.`,
    lat: 41.9280513,
    lon: 12.4557984,
    link: '/map',
    id: 5,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2y7fpw48.webp',
    name: 'Theatre of Marcellus',
    description: `Step back in time and witness the grandeur of ancient Rome at this remarkable open-air theatre, a testament to the city's rich cultural heritage.`,
    lat: 41.8919308,
    lon: 12.4799083,
    link: '/map',
    id: 5,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2y7cvvz7.webp',
    name: 'Tiber Island',
    description: `Explore the charming island nestled in the heart of Rome's Tiber River, known for its fascinating history, picturesque views, and enchanting atmosphere.`,
    lat: 41.8904524,
    lon: 12.4777395,
    link: '/map',
    id: 5,
    category: 10,
    type: 'O',
  },
  {
    img: './images/sr2yk4fu9r.webp',
    name: "Trajan's Column",
    description: `Uncover the stories of ancient Roman triumphs as you admire this iconic victory column, adorned with intricate reliefs depicting military campaigns and offering a glimpse into Rome's rich history.`,
    lat: 41.895833,
    lon: 12.484299,
    link: '/map',
    id: 5,
    category: 10,
    type: 'S',
  },
  {
    img: './images/sr2y7bbstk.webp',
    name: 'Trastevere',
    description: `Immerse yourself in the vibrant bohemian atmosphere of this charming neighborhood,
            known for its narrow cobbled streets, colorful buildings, and lively nightlife.`,
    lat: 41.8848294,
    lon: 12.4704017,
    link: '/map',
    id: 5,
    category: 10,
    type: 'Area',
  },
  {
    img: './images/sr2y7k3fnt.webp',
    name: 'Gardens of Vatican City',
    description: `Experience the serenity and natural beauty of these exclusive gardens, where lush
            greenery and stunning sculptures create a peaceful retreat within Vatican City.`,
    lat: 41.9036267,
    lon: 12.4502458,
    link: '/map',
    id: 5,
    category: 10,
    type: 'O',
  },
  {
    img: './images/sr2ykj1w1e.webp',
    name: 'Villa Medici',
    description: `Step into the world of Renaissance art and architecture as you explore this historic
            house, once a gathering place for prominent artists and intellectuals.`,
    lat: 41.9083567,
    lon: 12.4825788,
    link: '/map',
    id: 5,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2y70gxw3.webp',
    name: 'Villa Doria Pamphili',
    description: `Lose yourself in the sprawling beauty of Rome's largest landscaped park, featuring lush gardens, a stunning villa, and panoramic views of the city.`,
    lat: 41.8852783,
    lon: 12.4416091,
    link: '/map',
    id: 6,
    category: 10,
    type: 'B-P',
  },
  {
    img: './images/sr2ykwnysr.webp',
    name: 'Villa Torlonia',
    description: `Uncover the fascinating history of Mussolini's former residence, featuring a unique blend of architectural styles and stunning art installations.`,
    lat: 41.9139589,
    lon: 12.5118781,
    link: '/map',
    id: 6,
    category: 10,
    type: 'B-P',
  },
  {
    img: './images/sr2ykh852x.webp',
    name: 'Via Condotti',
    description: `Immerse yourself in the ultimate luxury shopping experience as you stroll along this
            prestigious thoroughfare, home to high-end fashion boutiques and renowned Italian
            designers.`,
    lat: 41.9051584,
    lon: 12.4805097,
    link: '/map',
    id: 6,
    category: '10',
    type: 'Street',
  },
  {
    img: './images/sr2y7dqrvj.webp',
    name: 'Villa Farnesina',
    description: `Immerse yourself in the enchanting world of Renaissance frescoes as you explore this
            magnificent villa adorned with masterpieces by Raphael and other renowned artists.`,
    lat: 41.8935994,
    lon: 12.4672868,
    link: '/map',
    id: 6,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2y7bwddp.webp',
    name: 'Villa del Priorato di Malta',
    description: `Discover the secret keyhole view that offers a unique glimpse of St. Peter's Basilica through the beautifully manicured gardens of this historic building.`,
    lat: 41.8830973,
    lon: 12.4785345,
    link: '/map',
    id: 6,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2yhghp3z.webp',
    name: 'Villa Maxentii',
    description: `Step back in time and marvel at the remarkably preserved ancient Roman ruins,
            including the stunningly intricate mosaic floors, that make this villa a must-visit
            for history enthusiasts.`,
    lat: 41.8537082,
    lon: 12.518972,
    link: '/map',
    id: 6,
    category: 10,
    type: 'B-M',
  },
  {
    img: './images/sr2vvxju6s.webp',
    name: 'Villa of the Quintilii',
    description: `Delve into the opulent lifestyle of ancient Roman aristocracy as you explore this
            grand villa, renowned for its colossal thermal baths and exquisite marble decorations.`,
    lat: 41.8312256,
    lon: 12.5543891,
    link: '/map',
    id: 6,
    category: 10,
    type: 'B-M',
  },
  {
    img: './images/sr2y78st0d.webp',
    name: 'Villa Sciarra',
    description: `Immerse yourself in the lush greenery of this park named for the villa, boasting
            beautiful gardens and a charming maze that offers a delightful escape from the
            bustling city.`,
    lat: 41.8834801,
    lon: 12.4646959,
    link: '/map',
    id: 6,
    category: 10,
    type: 'B-P',
  },
  {
    img: './images/sr2y7vpr7c.webp',
    name: 'Via Margutta',
    description: `Wander through this picturesque street, renowned for its vibrant art scene and
            charming boutiques, where you can discover unique artworks and shop for one-of-a-kind
            treasures.`,
    lat: 41.9085893,
    lon: 12.4795757,
    link: '/map',
    id: 6,
    category: 10,
    type: 'Street',
  },
  {
    img: './images/sr2ykhy8dr.webp',
    name: 'Via Veneto',
    description: `Immerse yourself in the glamorous ambiance of this iconic thoroughfare, known for its
            elegant cafes and historic hotels that have attracted celebrities and movie stars
            throughout the years.`,
    lat: 41.9061177,
    lon: 12.4895417,
    link: '/map',
    id: 6,
    category: 10,
    type: 'Street',
  },
  {
    img: './images/sr2ykkjphp.webp',
    name: 'Baths of Diocletian',
    description: `Step back in time as you explore the colossal ruins of this ancient Roman bath,
            marveling at its impressive architecture and imagining the grandeur of its past.`,
    lat: 41.9030822,
    lon: 12.4985214,
    link: '/map',
    id: 7,
    category: 10,
    type: 'B-M',
  },
  {
    img: './images/sr2ys9hd1y.webp',
    name: 'Catacomb of Priscilla',
    description: `Uncover the ancient Christian burial site within a former Roman quarry, offering a
            unique glimpse into the history of early Christian practices and customs.`,
    lat: 41.9296502,
    lon: 12.5087439,
    link: '/map',
    id: 7,
    category: 10,
    type: 'B-M',
  },
  {
    img: './images/sr2y7fwqzu.webp',
    name: 'Crypta Balbi',
    description: `Explore the layers of history within this captivating museum, where you can witness
            the evolution of ancient Rome through its remarkable archaeological remains and
            immersive exhibits.`,
    lat: 41.8948432,
    lon: 12.4784197,
    link: '/map',
    id: 7,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2y7uv01h.webp',
    name: 'Mausoleum of Augustus',
    description: `Discover the final resting place of the revered Roman Emperor, Augustus, and admire
            he monumental architecture that influenced future mausoleums throughout history.`,
    lat: 41.9060311,
    lon: 12.4764264,
    link: '/map',
    id: 7,
    category: 10,
    type: 'B-M',
  },
  {
    img: './images/sr2ykhtf74.webp',
    name: 'Capuchin Crypt',
    description: `Delve into the macabre beauty of this unique burial site, adorned with the skeletal
            remains of over 4,000 Capuchin friars, showcasing a hauntingly artistic display of
            human bones.`,
    lat: 41.9049618,
    lon: 12.4885354,
    link: '/map',
    id: 7,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2y7zjbrk.webp',
    name: 'National Etruscan Museum',
    description: `Immerse yourself in the fascinating world of the Etruscan civilization and explore
            ancient artifacts, revealing the unique customs and artistry of
            this enigmatic society.`,
    lat: 41.9183601,
    lon: 12.4777171,
    link: '/map',
    id: 7,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2y7d70k6.webp',
    name: 'Botanical Garden of the University of Rome',
    description: `Embark on a botanical journey through diverse ecosystems, featuring over 3,000 plant
            species, including an impressive collection of medicinal plants.`,
    lat: 41.8923247,
    lon: 12.4628307,
    link: '/map',
    id: 7,
    category: 10,
    type: 'O',
  },
  {
    img: './images/sr2ykhnznp.webp',
    name: 'Palazzo Barberini',
    description: `Explore a majestic palace that houses a captivating collection of ancient art at the Galleria Nazionale d'Arte Antica, showcasing masterpieces from renowned Italian artists.`,
    lat: 41.9031496,
    lon: 12.4900477,
    link: '/map',
    id: 7,
    category: 10,
    type: 'M-B',
  },
  {
    img: './images/sr2y7g1bb3.webp',
    name: 'Palazzo della Cancelleria',
    description: `Step into the world of Renaissance architecture as you admire the harmonious blend of
            Roman and Florentine styles in this historic building, once home to the Papal
            Chancellery.`,
    lat: 41.8964977,
    lon: 12.4718662,
    link: '/map',
    id: 7,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2y58hvs5.webp',
    name: 'Palazzo della Civiltà Italiana',
    description: `Discover a striking example of Fascist architecture, characterized by its iconic white
            marble facade and grand arches, offering a unique glimpse into Italy's
            architectural history.`,
    lat: 41.8368815,
    lon: 12.4652102,
    link: '/map',
    id: 7,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2yk5x422.webp',
    name: 'Palazzo delle Esposizioni',
    description: `Immerse yourself in the world of contemporary art and culture at this vibrant museum,
            showcasing thought-provoking exhibitions and innovative installations that push
            boundaries and inspire creativity.`,
    lat: 41.8994596,
    lon: 12.4901464,
    link: '/map',
    id: 8,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2yk52cd3.webp',
    name: 'Palazzo Doria-Pamphilj',
    description: `Step into the opulent world of aristocracy as you explore a magnificent palace
            boasting one of Rome's most impressive private art collections, featuring
            masterpieces by Caravaggio, Velázquez, and Bernini.`,
    lat: 41.8979782,
    lon: 12.4815742,
    link: '/map',
    id: 8,
    category: 10,
    type: 'B-M',
  },
  {
    img: './images/sr2y7ge1wy.webp',
    name: 'Palazzo Madama',
    description: `Uncover the heart of Italian politics as you explore the historic seat of the Senate
            of the Italian Republic, where important decisions shaping the nation are made.`,
    lat: 41.8993865,
    lon: 12.4738579,
    link: '/map',
    id: 8,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2yk4fwkx.webp',
    name: 'Palazzo Valentini',
    description: `Experience a captivating blend of ancient ruins and modern technology as you journey
            through the underground archaeological site, where virtual reconstructions bring the
            Roman past to life in a truly immersive way.`,
    lat: 41.8960502,
    lon: 12.484071,
    link: '/map',
    id: 8,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2y7vwze7.webp',
    name: 'Pincian Hill',
    description: `Soak in breathtaking panoramic views of Rome's iconic landmarks, including the
            Spanish Steps and St. Peter's Basilica, from this elevated vantage point that
            offers a stunning perspective of the city.`,
    lat: 41.9113789,
    lon: 12.4788593,
    link: '/map',
    id: 8,
    category: 10,
    type: 'B',
  },
  {
    img: './images/sr2ykggyt0.webp',
    name: 'Astronomical Museum with Planetarium',
    description: `Embark on a journey through the cosmos as you explore a fascinating
            museum and a state-of-the-art planetarium.`,
    lat: 41.9015738,
    lon: 12.5187927,
    link: '/map',
    id: 8,
    category: 10,
    type: 'M',
  },
  {
    img: './images/sr2yk5zrh5.webp',
    name: 'Pons Fabricius',
    description: `Step back in time and witness the remarkable resilience of ancient engineering as you cross the oldest Roman bridge, still standing proudly in its original form.`,
    lat: 41.901732,
    lon: 12.490575,
    link: '/map',
    id: 8,
    category: 10,
    type: 'Ponte',
  },
  {
    img: './images/sr2y7zn012.webp',
    name: 'Villa Giulia',
    description: `Delve into the world of ancient Etruscan art and culture at this captivating museum,
            housing a remarkable collection of intricate artifacts and stunning sculptures.`,
    lat: 41.918335,
    lon: 12.4777775,
    link: '/map',
    id: 8,
    category: 10,
    type: 'B-M',
  },
  {
    img: './images/sr2y7fr2sb.webp',
    name: 'Temple of Apollo Sosianus',
    description: `Step back in time and witness the remarkable preservation of this ancient Roman
            temple, showcasing intricate Corinthian columns that are a testament to the
            architectural brilliance of the past.`,
    lat: 41.8923302,
    lon: 12.4796429,
    link: '/map',
    id: 8,
    category: 10,
    type: 'B-M',
  },
  {
    img: './images/sr2yk46fzy.webp',
    name: 'Umbilicus urbis Romae',
    description: `Discover the ancient Roman "navel of the city"; where the empire's roads
            converged, offering a fascinating glimpse into the heart of Rome's urban
            planning.`,
    lat: 41.8927498,
    lon: 12.4845848,
    link: '/map',
    id: 8,
    category: 10,
    type: 'B-M',
  },
  {
    img: './images/sr2yk6hpd6.webp',
    name: 'Baths of Trajan',
    description: `Immerse yourself in the grandeur of ancient Rome as you explore the monumental remains
            of this colossal bathing and leisure complex, a testament to the opulence and
            architectural prowess of the Roman Empire.`,
    lat: 41.89217,
    lon: 12.4970512,
    link: '/map',
    id: 9,
    category: 10,
    type: 'B-M',
  },
  {
    img: './images/sr2y7cb850.webp',
    name: 'Santa Maria in Trastevere',
    description: `Immerse yourself in the vibrant atmosphere of Rome's oldest church, where a charming fountain stands as a symbol of community and unity.`,
    lat: 41.8895232,
    lon: 12.4702871,
    link: '/map',
    id: 9,
    category: 7,
    type: 'S-P',
  },
  {
    img: './images/sr2y7cq767.webp',
    name: 'Santa Maria in Cappella',
    description: `Discover a hidden treasure of Rome, as you marvel at the awe-inspiring frescoes that
            adorn the walls of this historic church building.`,
    lat: 41.8873253,
    lon: 12.4781713,
    link: '/map',
    id: 9,
    category: 10,
    type: 'C',
  },
  {
    img: './images/sr2y7c8pmn.webp',
    name: 'Pons Fabricius',
    description: `Step back in time and witness the remarkable resilience of ancient engineering as you
            cross the oldest Roman bridge, still standing proudly in its original form.`,
    lat: 41.8911198,
    lon: 12.4782798,
    link: '/map',
    id: 9,
    category: 7,
    type: 'C',
  },
  {
    img: './images/sr2y7cvdyj.webp',
    name: 'Pons Cestius',
    description: `Traverse this ancient Roman stone bridge to experience a tangible connection to the
            city's rich history and marvel at its remarkable architectural endurance.`,
    lat: 41.8899584,
    lon: 12.4772516,
    link: '/map',
    id: 9,
    category: 7,
    type: 'Ponte',
  },
  {
    img: './images/sr2y7f2byt.webp',
    name: 'Ponte Sisto',
    description: `Immerse yourself in the vibrant atmosphere of Trastevere as you stroll across this
            charming bridge, offering picturesque views of the Tiber River and access to the
            lively streets and quaint alleys of one of Rome's most authentic neighborhoods.`,
    lat: 41.8923568,
    lon: 12.4707504,
    link: '/map',
    id: 9,
    category: 7,
    type: 'Ponte',
  },
  {
    img: './images/sr2yednhef.webp',
    name: 'Ponte Milvio',
    description: `Explore the historic bridge where ancient traditions meet modern romance, as you
            witness the famous "love locks" that adorn its rails and soak in the
            enchanting atmosphere of this iconic landmark.`,
    lat: 41.9356402,
    lon: 12.4668987,
    link: '/map',
    id: 9,
    category: 7,
    type: 'Ponte',
  },
  {
    img: './images/sr2y7cxr3q.webp',
    name: 'Pons Aemilius',
    description: `Look at the ancient Roman stone bridge that has withstood the test of time,
            offering a glimpse into the remarkable engineering prowess of the past.`,
    lat: 41.8893593,
    lon: 12.479469,
    link: '/map',
    id: 9,
    category: 7,
    type: 'Ponte',
  },
  {
    img: './images/sr2y7cwy30.webp',
    name: 'Ponte Palatino',
    description: `Step onto this ancient bridge to witness the breathtaking convergence of ancient and
            modern Rome, as it connects the vibrant Trastevere neighborhood with the historic
            eart of the city.`,
    lat: 41.8891816,
    lon: 12.4788102,
    link: '/map',
    id: 9,
    category: 7,
    type: 'Ponte',
  },
  {
    img: './images/sr2y7eu79e.webp',
    name: 'Ponte Vittorio Emanuele II',
    description: `Experience the grandeur of this magnificent bridge adorned with statues and elegant
            arches, offering breathtaking views of the majestic St. Peter's Basilica and the
            Vatican City.`,
    lat: 41.9010817,
    lon: 12.4644061,
    link: '/map',
    id: 9,
    category: 7,
    type: 'Ponte',
  },
  {
    img: './images/sr2ye82zs4.webp',
    name: 'Ponte della Musica',
    description: `Visit Rome's musical bridge, where
            contemporary architecture meets the melodic sounds of live performances,
            creating a unique experience for music lovers and architecture enthusiasts alike.`,
    lat: 41.9265286,
    lon: 12.4597135,
    link: '/map',
    id: 9,
    category: 7,
    type: 'Ponte',
  },
  {
    img: './images/sr2ykk6nyf.webp',
    name: "Fontana dell'Acqua Felice",
    description: `Marvel at the impressive display of cascading water, as this monumental fountain
            showcases the mastery of Renaissance architecture and engineering.`,
    lat: 41.9043951,
    lon: 12.4944645,
    link: '/map',
    id: 10,
    category: 7,
    type: 'S',
  },
  {
    img: './images/sr2y79s5zn.webp',
    name: "Fontana dell'Acqua Paola",
    description: `Quench your thirst for history as you discover the origins of Rome's renowned
            water supply system at this ancient well.`,
    lat: 41.8888422,
    lon: 12.4642556,
    link: '/map',
    id: 10,
    category: 7,
    type: 'S',
  },
  {
    img: './images/sr2yk5kxcn.webp',
    name: 'Fontana dei Dioscuri',
    description: `Be captivated by the majestic sculptures of Castor and Pollux as they guard this
            historic water well, showcasing the artistry and rich history of ancient Rome.`,
    lat: 41.8991224,
    lon: 12.4866996,
    link: '/map',
    id: 10,
    category: 7,
    type: 'S',
  },
  {
    img: './images/sr2y7g6xk7.webp',
    name: 'Four Rivers Fountain',
    description: `Experience the genius of Gian Lorenzo Bernini as you witness the dynamic movement and
            symbolic representation of the four major rivers, making this fountain a true
            masterpiece of Baroque art.`,
    lat: 41.8989673,
    lon: 12.4730872,
    link: '/map',
    id: 10,
    category: 7,
    type: 'S',
  },
  {
    img: './images/sr2y7gds1b.webp',
    name: 'Fontana del Nettuno',
    description: `Immerse yourself in the captivating power of Neptune as you witness the grandeur of
            this monumental fountain, celebrating the mythological god of the sea.`,
    lat: 41.8998083,
    lon: 12.473027,
    link: '/map',
    id: 10,
    category: 7,
    type: 'S',
  },
  {
    img: './images/sr2y7gt34e.webp',
    name: 'Fontana del Pantheon',
    description: `Experience the harmonious blend of ancient architecture and flowing water as you
            marvel at this iconic fountain, nestled in the heart of Rome's historic Pantheon
            district.`,
    lat: 41.8993212,
    lon: 12.4767522,
    link: '/map',
    id: 10,
    category: 7,
    type: 'S',
  },
  {
    img: './images/sr2ykhmfep.webp',
    name: 'Piazza Barberini Fountain',
    description: `Marvel at the stunning Triton sculpture, showcasing the powerful and mythical figure
            of the sea god, making it a must-visit for art enthusiasts and history buffs alike.`,
    lat: 41.9036625,
    lon: 12.488483,
    link: '/map',
    id: 10,
    category: 7,
    type: 'S',
  },
  {
    img: './images/sr2y7vt590.webp',
    name: 'Flaminio Obelisk',
    description: `Discover the ancient origins of this towering monument, which once adorned the Circus
            Maximus and now stands as a remarkable testament to Rome's rich history.`,
    lat: 41.9107038,
    lon: 12.4763579,
    link: '/map',
    id: 10,
    category: 7,
    type: 'S',
  },
  {
    img: './images/sr2ys17u9y.webp',
    name: "Fontana dell'Acqua Acetosa",
    description: `Quench your thirst for history as you explore this unique fountain, known for its
            sparkling mineral water sourced from the nearby Acetosa Spring.`,
    lat: 41.9315146,
    lon: 12.4856548,
    link: '/map',
    id: 10,
    category: 7,
    type: 'S',
  },
  {
    img: './images/sr2ykh9qbr.webp',
    name: 'Fontana della Barcaccia',
    description: `Immerse yourself in the captivating charm of this sunken boat-shaped fountain,
            offering a unique and picturesque spot for relaxation and people-watching in the heart
            of Rome's bustling city center.`,
    lat: 41.9058025,
    lon: 12.4822463,
    link: '/map',
    id: 10,
    category: 7,
    type: 'S',
  },
  {
    img: './images/sr2ykk5kyt.webp',
    name: 'Fontana delle Naiadi',
    description: `Discover the enchanting allure of this grand fountain, adorned with magnificent
            statues representing the four mythological water nymphs, creating a captivating
            display of beauty and mythology.`,
    lat: 41.9027137,
    lon: 12.4962479,
    link: '/map',
    id: 11,
    category: 7,
    type: 'S-P',
  },
  {
    img: './images/sr2y7ftcj4.webp',
    name: 'Fontana delle Tartarughe',
    description: `Be captivated by the graceful bronze turtles that dance around this Renaissance
            fountain, a symbol of longevity and harmony, offering a delightful sight in the heart
            of Rome.`,
    lat: 41.8938343,
    lon: 12.4775765,
    link: '/map',
    id: 11,
    category: 7,
    type: 'S',
  },
  {
    img: './images/sr2y7gzd2m.webp',
    name: 'Fontana di Piazza Colonna',
    description: `Immerse yourself in the rich history of Rome as you admire the grandeur of this
            monumental fountain, adorned with an ancient Egyptian obelisk that stands as a
            testament to the city's enduring legacy.`,
    lat: 41.9008825,
    lon: 12.4797673,
    link: '/map',
    id: 11,
    category: 7,
    type: 'S',
  },
  {
    img: './images/sr2yk48mhk.webp',
    name: "Fontana di Piazza d'Aracoeli",
    description: `Experience the charm of this historic fountain, featuring a unique blend of Roman and
            medieval architectural elements, offering a glimpse into the rich cultural heritage of
            the city.`,
    lat: 41.8944683,
    lon: 12.4809887,
    link: '/map',
    id: 11,
    category: 7,
    type: 'S',
  },
  {
    img: './images/sr2y7fcfrk.webp',
    name: `Campo de' Fiori`,
    description: `Immerse yourself in the vibrant atmosphere of this bustling square, famous for its
            lively market that offers a tantalizing array of fresh produce, flowers, and local
            specialties, making it a must-visit for food enthusiasts and culture seekers alike.`,
    lat: 41.8954097,
    lon: 12.472202,
    link: '/map',
    id: 11,
    category: 7,
    type: 'S-P',
  },
  {
    img: './images/sr2yk4xx2x.webp',
    name: 'Fontana dei Catecumeni',
    description: `Marvel at the unique blend of ancient and modern art as you admire this captivating
            fountain, adorned with sculptures representing the stages of Christian initiation,
            offering a glimpse into Rome's rich religious history.`,
    lat: 41.8948812,
    lon: 12.4908368,
    link: '/map',
    id: 11,
    category: 7,
    type: 'S',
  },
  {
    img: './images/sr2ykk5te0.webp',
    name: "Fountains of St. Peter's Square",
    description: `Discover the awe-inspiring sight of these magnificent fountains, each featuring an
            impressive centerpiece that symbolizes the harmony between water and faith, creating a
            serene ambiance in the heart of Rome.`,
    lat: 41.9027835,
    lon: 12.4963655,
    link: '/map',
    id: 11,
    category: 7,
    type: 'S-P',
  },
  {
    img: './images/sr2y7g6dne.webp',
    name: 'Fontana del Moro',
    description: `Admire the powerful sculpture of a Moorish man wrestling with a dolphin, showcasing
            the intricate artistry and symbolism of this unique fountain in Rome.`,
    lat: 41.8981206,
    lon: 12.4731517,
    link: '/map',
    id: 11,
    category: 7,
    type: 'S-P',
  },
  {
    img: './images/sr2y7fc2hd.webp',
    name: 'Fontane di Piazza Farnese',
    description: `Explore these two fountains, adorned with magnificent granite basins that once served
            as bathtubs for Roman emperors.`,
    lat: 41.8950097,
    lon: 12.4714431,
    link: '/map',
    id: 11,
    category: 7,
    type: 'S-P',
  },
]
