import '@/assets/styles/app.scss'
import '@/core/i18n'

import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { resetHookTracker } from '@/core/hooks/useHookTracker'
import { persistor, store } from '@/core/store'
import { APP_VERSION } from '@/core/utils/dev'

import App from './App'

// Initialize Sentry
// Sentry.init({
//   dsn: 'https://8420524eca59ce22b41d90e372f20bd2@o4507771994308608.ingest.de.sentry.io/4507771997651024',
//   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

const root = createRoot(document.getElementById('root') as HTMLElement)

console.log(`APP Version:: ${APP_VERSION}`)

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        {/* {resetHookTracker()} */}
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
)
