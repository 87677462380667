import Cookies from 'js-cookie'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { useAppDispatch } from '@/core/store'
import { setCookiesAccepted } from '@/core/store/ui.store'

const Cookie: React.FC = () => {
  const [showing, setShowing] = React.useState(false)
  const dispatch = useAppDispatch()
  const { t } = useTranslation('cookie')

  const [searchParams] = useSearchParams()

  useEffect(() => {
    // console.log(window.location.origin)
    if (searchParams.get('ref') && searchParams.get('ref') == 'online') {
      setShowing(false)
      Cookies.set('cookiesAccepted', 'true')
      dispatch(setCookiesAccepted(true))
      return
    }

    if (!Cookies.get('cookiesAccepted')) {
      setShowing(true)
    }
  }, [])

  const handleAccept = useCallback(() => {
    Cookies.set('cookiesAccepted', 'true')
    dispatch(setCookiesAccepted(true))
    setShowing(false)
  }, [dispatch])

  const message = t(
    'text',
    'We use cookies to remember the state of the application and to perform anonymous analysis of usage. <br />Please do not use the application if this is unacceptable for you.',
  )

  if (!showing) return null

  return (
    <>
      <div className="cookie active">
        <p dangerouslySetInnerHTML={{ __html: message }} />
        <button onClick={handleAccept}>{t('accept')}</button>
      </div>
      <div className="cookie-bg" />
    </>
  )
}

export default Cookie
